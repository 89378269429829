import InView from 'inview';

(function(){

    $(function () {
    
    // Inside carousel
    
let slider = document.getElementsByClassName('js-slider')[0];

if (slider) {

    let inview = InView(slider, function(isInView, data) {
        if (isInView) {
        //if (data.elementOffsetTopInViewHeight < data.inViewHeight - 100) {
        
            $('.js-slider').slick({
                autoplay: true,
                dots: true,
                appendDots: $('#insideDots'),
                customPaging : function(slider, i) {
                    return '<i class="dot"></i>';
                },
                nextArrow: $('.c-inside .next'),
                prevArrow: $('.c-inside .prev'),
                infinite: true
            });

            $('.js-slider').on('afterChange', function(event, slick, direction){
                $('.js-slideNum').text(slick.currentSlide + 1);
            });
            
            this.destroy();

            
       //}
       }
        
        console.log(data.elementOffsetTopInViewHeight + ' ' + data.inViewHeight);
    });
}
    
    
    
    // Details carousel
    
    let details = document.getElementsByClassName('js-details')[0];
    
    if (details) {

        let inview = InView(slider, function(isInView, data) {
            
            if (data.elementOffsetTopInViewHeight < data.inViewHeight - 100) {
            
                $('.js-details').slick({
                    autoplay: true,
                    autoplaySpeed: 2000,
                    nextArrow: $('.c-details .next'),
                    prevArrow: $('.c-details .prev'),
                    infinite: true,
                    speed: 500,
                    adaptiveHeight: true
                });

                this.destroy();
            }
        });
    }
    




   if($('.can-fixed').length)
    {
      
      $(window).scroll(function(){
        $('.can-fixed').each(function(i, elem){
            if (window.innerWidth >= 1050) {
              elem=$(elem);
              if($(document).scrollTop()>elem.parent().offset().top-80) { elem.addClass('fixed'); $('.can-fixed').css({marginTop: 0}); }
              else { elem.removeClass('fixed'); elem.css({marginTop: 0}); }
              var container=elem.parents('.columns');
              if(!container.length) { container=elem.parents('.container-fluid'); }
              if((container.offset().top+container.height())<($(document).scrollTop()+elem.height())) {
              
              elem.removeClass('fixed');
              elem.css({marginTop: Math.max(0, (container.height()-elem.height()-10))+'px'});
            }
          
          }
        });
        
      });
    }
   
    
  });
})();