import GLightbox from 'glightbox';
require('../../node_modules/glightbox/dist/css/glightbox.css');

document.addEventListener('DOMContentLoaded',function() {
    
    let lightboxDescription = GLightbox({
        selector: 'js-video'
    });

}, false);
