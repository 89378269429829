document.addEventListener('DOMContentLoaded',function() {
	
	const faq = document.getElementsByClassName('js-faq')[0];

	const init = function() {
	
	    const question = faq.getElementsByClassName('js-question');

	    const openAnswer = function(e) {
    	    const target = this.parentNode;
    	    
    	    if (target.classList.contains('open')) {
        	    target.classList.remove('open');
            } else {
                target.classList.add('open');
            }
	    }
	    
	    for (let i = 0; i < question.length; i ++) {
    	    question[i].addEventListener('click', openAnswer);
	    }
	};
		
	faq ? init() : false;
	
	


}, false);



