import serialize from 'form-serialize';
import validate from 'validate.js';

document.addEventListener('DOMContentLoaded',function() {
	
	const form = document.getElementById('contact-form');

	let status = false;

	const init = function() {
	
   	    const fields = form.getElementsByClassName('js-fields')[0],
   	          layerPopup = document.getElementsByClassName('layer-popup')[0],
   	          popupOuter = document.getElementsByClassName('popup-outer')[0],
              thanks = form.getElementsByClassName('js-thanks')[0],
              submit = document.getElementById('submit');

	    const hideForm = function() {
            layerPopup.classList.remove('show');
	        popupOuter.classList.remove('show');
	        document.removeEventListener('click', clickOutside);
	    }
	    
	    const showForm = function(ev) {
	        fields.classList.remove('is-hidden');
            layerPopup.classList.add('show');
	        popupOuter.classList.add('show');
            thanks.classList.add('is-hidden');
            
            document.addEventListener('click', clickOutside);
            ev.preventDefault() ? ev.preventDefault() : ev.preventDefault = false;
	    }
	    
	    const clickOutside = function(e) {
            let target = e.target.parentNode;              
            if (target.id === ('all-inner')) {
                hideForm();
            } 
        }

		const sendData = function() {
			
			var request = new XMLHttpRequest(),
				data = serialize(form),
				action = document.forms[0].getAttribute('action');
				
            console.log(data);

			request.open('POST', action, true);
			request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
			request.onload = function() {
				if (request.status >= 200 && request.status < 400) {
				
					var resp = request.responseText;
					
					console.log(resp);

					if (resp === 'Mail sent') {
						fields.classList.add('is-hidden');
						thanks.classList.remove('is-hidden');
					} else {
					    console.log('problem with send');
					}
						
				} else {
					console.log('Status: ' + request.status);
				}
				
				submit.classList.remove('is-busy');
			};
			
			request.onerror = function() {
				// There was a connection error of some sort
			};
			
			request.send(data);

		};

		const validatejs = function() {

			// These are the constraints used to validate the form
			var constraints = {
				email: {
					presence: true,
					email: true
				},
				agree: {
                    presence: {
                        message: "^You need to check the checkbox"
                    },
                    inclusion: {
                        within: [true],
                        message: "^You need to check the checkbox"
                    }
                }
			};


			// Hook up the form so we can prevent it from being posted

			form.addEventListener("submit", function(ev) {
				handleFormSubmit(form);
				ev.preventDefault() ? ev.preventDefault() : ev.preventDefault = false;
			});


			// Hook up the inputs to validate on the fly
			var inputs = document.querySelectorAll("input, textarea, select");

			for (var i = 0; i < inputs.length; ++i) {
				inputs.item(i).addEventListener("change", function(ev) {
					var errors = validate(form, constraints) || {};
					showErrorsForInput(this, errors[this.name]);
				});
			}
	

			function handleFormSubmit(form, input) {
			
			    submit.classList.add('is-busy');
			
				// validate the form aainst the constraints
				var errors = validate(form, constraints);
				// then we update the form to reflect the results

				showErrors(form, errors || {});

				if (!errors) {
					showSuccess();
				} else {
				    submit.classList.remove('is-busy');	
				}
			}
	
			// Updates the inputs with the validation errors
			function showErrors(form, errors) {
				var arr = form.querySelectorAll("input[name]");
			
				for (var i = 0; i < arr.length; i ++ ) {
					var input = arr[i];
					showErrorsForInput(input, errors && errors[input.name]);
				}
			}


			// Shows the errors for a specific input
			function showErrorsForInput(input, errors) {
				
				// This is the root of the input

				let formGroup = closestParent(input.parentNode, 'form-group');
				
                console.log(input + ' ' + errors);
					//messages = formGroup.querySelector(".messages");
			
				// First we remove any old messages and resets the classes
				resetFormGroup(formGroup);
			
				// If we have errors
                if (errors) {
					// we first mark the group has having errors
					formGroup.classList.add("has-error");
			
				} else {
                    // otherwise we simply mark it as success
                    formGroup.classList.add("has-success");
                }
			}

			
			// Recusively finds the closest parent that has the specified class
			function closestParent(child, className) {
				if (!child || child == document) {
					return null;
				}

				if (child.classList.contains(className)) {
					return child;
				} else {
					return closestParent(child.parentNode, className);
				}
			}


			function resetFormGroup(formGroup) {
				// Remove the success and error classes
				formGroup.classList.remove("has-error");
				formGroup.classList.remove("has-success");
				// and remove any old messages
				
				
/*
				var arr = formGroup.querySelectorAll(".help-block.error");
			
				for (var i = 0; i < arr.length; i ++ ) {
					var el = arr[i];

					el.parentNode.removeChild(el);
				}
*/
			}


			// Adds the specified error with the following markup
			// <p class="help-block error">[message]</p>
			function addError(messages, error) {
				var block = document.createElement("span");

				//block.classList.add("help-block");
				block.classList.add("error");
				block.innerText = '('+error+')';
				messages.appendChild(block);
			}

		
			function showSuccess() {
				sendData();
			}
		};
		
		validatejs();
		
		document.getElementsByClassName('js-openContactForm')[0].addEventListener('click', showForm);
		document.getElementsByClassName('js-closeContactForm')[0].addEventListener('click', hideForm);
		document.addEventListener('keydown', function(evt) {

            let isEscape = false;

            if ("key" in evt) {
                isEscape = (evt.key == "Escape" || evt.key == "Esc");
            } else {
                isEscape = (evt.keyCode == 27);
            }
            
            if (isEscape) {
                hideForm();
            }
        });
		

	};
		
	form ? init() : false;
	
	


}, false);



